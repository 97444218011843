import React, { useState, useEffect, useCallback } from "react";
import ApiService from "../ApiService";
import { debounce } from "lodash";
import Modal from "../Modal";

const TaskTable = ({
  apiUrl,
  columns,
  selectedRow,
  setSelectedRow,
  listener,
  buttonOff = false,
}) => {
  const [items, setItems] = useState([]);
  const [tasksIds, setTasksIds] = useState([]);
  const [overdue, setOverdue] = useState(0);
  const [unreadComment, setUnreadComment] = useState(0);
  const [unread, setUnread] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(selectedRow);
  const [activeSearchColumn, setActiveSearchColumn] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 50;

  const loadDateFromLocalStorage = (key, defaultValue) => {
    const savedDate = localStorage.getItem(key);
    return savedDate ? new Date(savedDate) : defaultValue;
  };

  const loadBooleanFromLocalStorage = (key, defaultValue) => {
    const savedValue = localStorage.getItem(key);
    return savedValue ? JSON.parse(savedValue) : defaultValue;
  };

  const [like, setLike] = useState(() =>
    loadBooleanFromLocalStorage("like", false)
  );

  useEffect(() => {
    localStorage.setItem("like", JSON.stringify(like));
  }, [like]);
  const getDefaultStartDate = () => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const getDefaultEndDate = () => {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
  };

  const [startDate, setStartDate] = useState(() =>
    loadDateFromLocalStorage("startDate", getDefaultStartDate())
  );
  const [endDate, setEndDate] = useState(() => getDefaultEndDate());
  const [deadline, setDeadline] = useState(() =>
    loadBooleanFromLocalStorage("deadline", false)
  );
  const [dropDone, setDropDone] = useState(() =>
    loadBooleanFromLocalStorage("dropDone", false)
  );

  const getRowClass = (item) => {
    const classes = [];
    if (
      (item.priority === "Высокий" || item.priority === "Очень высокий") &&
      item.status !== "Завершена"
    ) {
      classes.push("fire-animation");
    }
    if (
      item.deadline &&
      new Date(item.deadline) < new Date() &&
      item.status !== "Завершена"
    ) {
      classes.push("highlight-red");
    }
    return classes.join(" ");
  };

  useEffect(() => {
    localStorage.setItem("startDate", startDate.toISOString());
  }, [startDate]);

  useEffect(() => {
    localStorage.setItem("endDate", endDate.toISOString());
  }, [endDate]);

  useEffect(() => {
    localStorage.setItem("deadline", JSON.stringify(deadline));
  }, [deadline]);

  useEffect(() => {
    localStorage.setItem("dropDone", JSON.stringify(dropDone));
  }, [dropDone]);

  useEffect(() => {
    setSelectedRowId(selectedRow);
  }, [selectedRow]);

  const [webSocket, setWebSocket] = useState(null);

  useEffect(() => {
    if (webSocket && webSocket.type === "commentUp") {
      const taskId = webSocket.message;
      if (taskId in tasksIds) {
        // проверка на наличие ключа
        setUnreadComment((prevCount) => parseInt(prevCount, 10) + 1);
      }
    }
    if (webSocket && webSocket.type === "commentDown") {
      const taskId = webSocket.taskId;
      const count = webSocket.message;
      if (taskId in tasksIds) {
        // проверка на наличие ключа
        setUnreadComment((prevCount) => parseInt(prevCount, 10) - count);
      }
    }
  }, [webSocket]);

  useEffect(() => {
    ApiService.connectWebSocket("/comment", setWebSocket);
  }, []);

  const fetchData = useCallback(
    debounce(async () => {
      setIsLoading(true); // Устанавливаем состояние загрузки
      try {
        const params = {
          search: searchTerm || "",
          column: activeSearchColumn || "",
          status: dropDone ? "не завершена" : undefined,
          checkDeadline: deadline,
          comment: unread,
          startDate: startDate,
          endDate: endDate,
          like: like,
          page: currentPage,
          itemsPerPage: itemsPerPage,
        };
        const response = await ApiService.fetchDataWithToken(apiUrl, params);
        const { tasks, totalItems, overdueCount, unreadComments, tasksIds } =
          response;
        setItems(tasks ?? []);
        setTasksIds(tasksIds ?? []);
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
        setOverdue(overdueCount);
        setUnreadComment(unreadComments);
      } catch (error) {
        console.error("Ошибка при загрузке данных: ", error);
      } finally {
        setIsLoading(false); // Отключаем состояние загрузки после завершения запроса
      }
    }, 300),
    [
      apiUrl,
      dropDone,
      startDate,
      endDate,
      searchTerm,
      activeSearchColumn,
      deadline,
      currentPage,
      unread,
      like,
    ]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [
    apiUrl,
    searchTerm,
    dropDone,
    startDate,
    endDate,
    activeSearchColumn,
    deadline,
    unread,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData, listener]);

  const handleRowClick = (id, deadline, status, pauseId) => {
    setSelectedRowId(id);
    setSelectedRow(id, deadline, status, pauseId);
  };

  const handleColumnHeaderClick = (columnKey) => {
    setActiveSearchColumn((prevKey) =>
      prevKey === columnKey ? null : columnKey
    );
  };

  const formatDateTime = (value) => {
    const date = new Date(value);
    if (date.getTime() === 0) return "не указано";
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const loadColumnVisibilityFromLocalStorage = () => {
    const savedVisibility = localStorage.getItem("columnVisibility");
    return savedVisibility ? JSON.parse(savedVisibility) : {};
  };

  const [columnVisibility, setColumnVisibility] = useState(() =>
    loadColumnVisibilityFromLocalStorage()
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleColumnVisibilityChange = (key) => {
    setColumnVisibility((prevVisibility) => {
      const newVisibility = { ...prevVisibility, [key]: !prevVisibility[key] };
      localStorage.setItem("columnVisibility", JSON.stringify(newVisibility));
      return newVisibility;
    });
  };

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedWidths = localStorage.getItem("columnWidths");
    return savedWidths ? JSON.parse(savedWidths) : {};
  });

  const [columnOrder, setColumnOrder] = useState(() => {
    const savedOrder = localStorage.getItem("columnOrder");
    return savedOrder ? JSON.parse(savedOrder) : columns.map((col) => col.key);
  });

  const handleResizeStart = (e, columnKey) => {
    e.preventDefault();
    e.stopPropagation();

    const startX = e.type === "mousedown" ? e.clientX : e.touches[0].clientX;
    const columnHeader = e.target.parentElement;
    const startWidth = columnWidths[columnKey] || columnHeader.offsetWidth;

    const handleMouseMove = (moveEvent) => {
      const moveX =
        moveEvent.type === "mousemove"
          ? moveEvent.clientX
          : moveEvent.touches[0].clientX;
      const newWidth = Math.max(startWidth + moveX - startX, 50);

      columnHeader.style.width = `${newWidth}px`;
    };

    const handleMouseUp = () => {
      const newWidth = parseFloat(columnHeader.style.width);

      setColumnWidths((prevWidths) => {
        const updatedWidths = { ...prevWidths, [columnKey]: newWidth };
        localStorage.setItem("columnWidths", JSON.stringify(updatedWidths));
        return updatedWidths;
      });

      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleMouseMove);
      document.removeEventListener("touchend", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleMouseMove);
    document.addEventListener("touchend", handleMouseUp);
  };

  const handleLike = async (taskId) => {
    try {
      await ApiService.postDataWithToken("/favorites/like", { taskId: taskId });
      fetchData();
    } catch (error) {
      console.error("Ошибка при добавлении/удалении лайка: ", error);
    }
  };

  const getLastVisibleColumnKey = () => {
    const visibleColumns = columns.filter(
      (column) => columnVisibility[column.key] !== false
    );
    return visibleColumns.length > 0
      ? visibleColumns[visibleColumns.length - 1].key
      : null;
  };

  const visibleColumns = columnOrder.filter(
    (key) => columnVisibility[key] !== false
  );
  return (
    <div className="grid-container">
      {!buttonOff && (
        <>
          <div className="search-container">
            <div className="search-input-container">
              <input
                type="text"
                placeholder="Поиск"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="search-icon">
                <i className="fas fa-search"></i>
              </span>
            </div>
            <div className="date-picker-container desktop-only">
              <div className="date-picker">
                <input
                  type="date"
                  name="startDate"
                  value={startDate.toISOString().split("T")[0]}
                  onChange={(e) => {
                    const value = e.target.value;
                    const date = new Date(value);
                    if (!isNaN(date.getTime())) setStartDate(date);
                  }}
                  disabled={like}
                  max={endDate.toISOString().split("T")[0]}
                />
              </div>
              <div className="date-picker">
                <input
                  type="date"
                  name="endDate"
                  value={endDate.toISOString().split("T")[0]}
                  onChange={(e) => {
                    const value = e.target.value;
                    const date = new Date(value);
                    if (!isNaN(date.getTime())) setEndDate(date);
                  }}
                  disabled={like}
                  min={startDate.toISOString().split("T")[0]}
                />
              </div>
            </div>
            <button
              className="filter-toggle mobile-only"
              onClick={() => setIsMobileView((prev) => !prev)}
            >
              {"фильтр"}
              <span className={`arrow ${isMobileView ? "open" : ""}`}>▼</span>
            </button>
          </div>
          <div
            className={`filter-content ${
              isMobileView ? "open" : "closed"
            } mobile-only`}
          >
            <div className="date-picker-container mobile-only">
              <div className="date-picker">
                <input
                  type="date"
                  name="startDate"
                  value={startDate.toISOString().split("T")[0]}
                  onChange={(e) => {
                    const value = e.target.value;
                    const date = new Date(value);
                    if (!isNaN(date.getTime())) setStartDate(date);
                  }}
                  disabled={like}
                  max={endDate.toISOString().split("T")[0]}
                />
              </div>
              <div className="date-picker">
                <input
                  type="date"
                  name="endDate"
                  value={endDate.toISOString().split("T")[0]}
                  onChange={(e) => {
                    const value = e.target.value;
                    const date = new Date(value);
                    if (!isNaN(date.getTime())) setEndDate(date);
                  }}
                  disabled={like}
                  min={startDate.toISOString().split("T")[0]}
                />
              </div>
            </div>
            <button onClick={() => setIsModalOpen(true)}>
              Настроить колонки
            </button>
            <button
              className={`status-button ${
                deadline ? "overdue" : "not-overdue"
              }`}
              onClick={() => setDeadline((prev) => !prev)}
              disabled={like}
            >
              Просрочены
              <span className="status-count">{overdue}</span>
            </button>
            <button
              className={`status-button ${unread ? "overdue" : "not-overdue"}`}
              onClick={() => setUnread((prev) => !prev)}
              disabled={like}
            >
              Комментарии
              <span className="status-count">{unreadComment}</span>
            </button>
            <label className="checkbox-container">
              <input
                style={{ height: "30px", width: "30px" }}
                type="checkbox"
                className="checkbox-input"
                checked={dropDone}
                onChange={() => setDropDone((prev) => !prev)}
                disabled={like}
              />
              <span className="checkmark" disabled={like}></span>
              <span className="checkbox-label">
                Убрать завершенные и отложенные
              </span>
            </label>
            <label className="checkbox-container">
              <input
                style={{ height: "30px", width: "30px" }}
                type="checkbox"
                className="checkbox-input"
                checked={like}
                onChange={() => setLike((prev) => !prev)}
              />
              <span className="checkmark"></span>
              <span className="checkbox-label">Избранное</span>
            </label>
          </div>
        </>
      )}

      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              {columns.map(
                (column) =>
                  columnVisibility[column.key] !== false && (
                    <th
                      key={column.key}
                      className={
                        activeSearchColumn === column.key
                          ? "selected-column"
                          : ""
                      }
                      style={{ width: columnWidths[column.key] || "auto" }}
                      onClick={() => handleColumnHeaderClick(column.key)}
                    >
                      {column.title}
                      <div
                        className="resizer-area"
                        onMouseDown={(e) => handleResizeStart(e, column.key)}
                        onTouchStart={(e) => handleResizeStart(e, column.key)}
                      ></div>
                    </th>
                  )
              )}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td
                  colSpan={columns.length}
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    border: "none",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="spinner"></div>
                  </div>
                </td>
              </tr>
            ) : items.length === 0 ? (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center" }}>
                  Данные не найдены
                </td>
              </tr>
            ) : (
              items.map((item) => (
                <tr
                  key={item.id}
                  className={`${
                    selectedRowId === item.id ? "selected" : ""
                  } ${getRowClass(item)}`}
                  onClick={() =>
                    handleRowClick(
                      item.id,
                      item.deadline,
                      item.status,
                      item.pauseId
                    )
                  }
                >
                  {columns.map(
                    (column) =>
                      columnVisibility[column.key] !== false && (
                        <td key={column.key}>
                          {column.date
                            ? formatDateTime(item[column.key])
                            : column.key === "id"
                            ? item[column.key]
                            : item[column.key]}
                          {/* Insert like button in the last visible column */}
                          {column.key === getLastVisibleColumnKey() && (
                            <div className="like-container">
                              <div
                                className={`like-button ${
                                  item.isLiked ? "liked" : ""
                                }`}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent row click event
                                  handleLike(item.id);
                                }}
                              >
                                <i
                                  className={`fas fa-heart ${
                                    item.isLiked ? "filled" : "empty"
                                  }`}
                                ></i>
                              </div>
                            </div>
                          )}
                        </td>
                      )
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lt;
        </button>
        <span>{`Страница ${currentPage} из ${totalPages}`}</span>
        <button
          className="button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>
      <Modal active={isModalOpen} setActive={setIsModalOpen}>
        <div
          style={{
            backgroundColor: "#ffffff",
            maxWidth: "500px",
            width: "100%",
            fontFamily: "Arial, sans-serif",
            color: "#333",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "15px",
              marginTop: "10px",
              borderBottom: "2px solid #007bff",
              paddingBottom: "10px",
            }}
          >
            Выбор колонок
          </p>
          <ul
            style={{
              listStyle: "none",
              padding: "0",
              margin: "0",
            }}
          >
            {columns.map((column) => (
              <li
                key={column.key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #ddd",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "4px",
                  marginBottom: "8px",
                }}
              >
                <label className="checkbox-container">
                  <input
                    style={{ height: "20px", width: "20px" }}
                    type="checkbox"
                    className="checkbox-input"
                    checked={columnVisibility[column.key] !== false}
                    onChange={() => handleColumnVisibilityChange(column.key)}
                  />
                  <span className="checkmark"></span>
                  <span>{column.title}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </div>
  );
};

export default TaskTable;
