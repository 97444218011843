import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ApiService from "../../components/ApiService";
import CustomToast from "../../components/CustomToast";
import goldMedal from "../../assets/images/gold_medal.png";
import silverMedal from "../../assets/images/silver_medal.png";
import bronzeMedal from "../../assets/images/bronze_medal.png";
import "./userInfo.scss";

const Kpi = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tableMounth, setTableMounth] = useState([]);
  const [table, setTable] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);

  const navigate = useNavigate(); // Используем useNavigate для навигации

  const startDate = useMemo(() => {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    return startDate;
  }, []);

  const endDate = useMemo(() => {
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);
    return endDate;
  }, []);

  const MounthStart = useMemo(() => {
    const MounthStart = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    MounthStart.setHours(0, 0, 0, 0);
    return MounthStart;
  }, [currentDate]);

  const MounthEnd = useMemo(() => {
    const MounthEnd = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );
    MounthEnd.setHours(23, 59, 59, 999);
    return MounthEnd;
  }, [currentDate]);

  useEffect(() => {
    // Update currentDate every minute (60000 ms)
    const intervalId = setInterval(() => {
      setCurrentDate(new Date()); // Just updates currentDate
    }, 60 * 1000); // 1 minute

    // Reload page every 5 minutes
    const reloadPage = setTimeout(() => {
      window.location.reload();
    }, 5 * 60 * 1000); // 5 minutes

    return () => {
      clearInterval(intervalId);
      clearTimeout(reloadPage);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dayResponse, mounthResponse] = await Promise.all([
          ApiService.fetchDataWithToken("/kpi/amount", { startDate, endDate }),
          ApiService.fetchDataWithToken("/kpi/amount", {
            startDate: MounthStart,
            endDate: MounthEnd,
          }),
        ]);
        if (mounthResponse && dayResponse) {
          setTableMounth(mounthResponse.companies);
          setTable(dayResponse.companies);
        }
      } catch (error) {
        setToastType("error");
        setToastMessage(error.response.data.error);
      }
    };
    fetchData();
  }, [startDate, endDate, MounthStart, MounthEnd]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const tableMounthMap = tableMounth.reduce((map, element) => {
    map[element.userId] = element;
    return map;
  }, {});

  const mergedData = table.map((element) => ({
    ...element,
    inprogressmounth: tableMounthMap[element.userId].inprogress,
    onholdmounth: tableMounthMap[element.userId].onhold,
    completedmounth: tableMounthMap[element.userId].completed,
  }));

  const sortedData = mergedData.sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];
    if (typeof aValue !== "undefined" && typeof bValue !== "undefined") {
      if (sortDirection === "asc") {
        return aValue.localeCompare(bValue, undefined, { numeric: true });
      } else {
        return bValue.localeCompare(aValue, undefined, { numeric: true });
      }
    } else {
      return 0;
    }
  });

  const getTotalRow = () => {
    let totalInProgress = 0;
    let totalOnHold = 0;
    let totalCompleted = 0;
    let totalInProgressMounth = 0;
    let totalOnHoldMounth = 0;
    let totalCompletedMounth = 0;

    sortedData.forEach((element) => {
      totalInProgress += parseInt(element.inprogress, 10) || 0;
      totalOnHold += parseInt(element.onhold, 10) || 0;
      totalCompleted += parseInt(element.completed, 10) || 0;
      totalInProgressMounth += parseInt(element.inprogressmounth, 10) || 0;
      totalOnHoldMounth += parseInt(element.onholdmounth, 10) || 0;
      totalCompletedMounth += parseInt(element.completedmounth, 10) || 0;
    });

    return (
      <tr>
        <td className="user-info-name">Всего</td>
        <td className="user-info-td">{totalInProgress}</td>
        <td className="user-info-td">{totalOnHold}</td>
        <td className="user-info-td">{totalCompleted}</td>
        <td className="user-info-td">{totalInProgressMounth}</td>
        <td className="user-info-td">{totalOnHoldMounth}</td>
        <td className="user-info-td">{totalCompletedMounth}</td>
      </tr>
    );
  };

  useEffect(() => {
    handleSort("completed");
    setSortDirection("desc");
    document.title = "Рейтинг";
  }, []);

  const getMedalImage = (index) => {
    if (index === 0) {
      return <img className="image" src={goldMedal} alt="goldMedal" />;
    } else if (index === 1) {
      return <img className="image" src={silverMedal} alt="silverMedal" />;
    } else if (index === 2) {
      return <img className="image" src={bronzeMedal} alt="bronzeMedal" />;
    } else {
      return null;
    }
  };

  const handleUserClick = (userId) => {
    const role = localStorage.getItem("role");
    if (role === "admin" || role === "manager") {
      navigate("/ktasks", { state: { USER_ID: userId } });
    } else {
      console.log("Access denied."); // Вы можете обработать это сообщение по своему усмотрению
    }
  };

  return (
    <div className="user-info-container">
      <div className="user-info-time">
        Текущая дата:{" "}
        {currentDate.toLocaleString("default", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </div>
      <table className="user-info-table">
        <thead>
          <tr>
            <th className="user-info-name" rowSpan="2">
              Имя сотрудника
            </th>
            <th className="user-info-th" colSpan="3">
              День
            </th>
            <th className="user-info-th" colSpan="3">
              Месяц
            </th>
          </tr>
          <tr>
            <th
              className={
                sortColumn === "inprogress"
                  ? "user-info-th user-info-selected-column"
                  : "user-info-th"
              }
              onClick={() => handleSort("inprogress")}
            >
              В работе
            </th>
            <th
              className={
                sortColumn === "onhold"
                  ? "user-info-th user-info-selected-column"
                  : "user-info-th"
              }
              onClick={() => handleSort("onhold")}
            >
              На паузе
            </th>
            <th
              className={
                sortColumn === "completed"
                  ? "user-info-th user-info-selected-column"
                  : "user-info-th"
              }
              onClick={() => handleSort("completed")}
            >
              Завершены
            </th>
            <th
              className={
                sortColumn === "inprogressmounth"
                  ? "user-info-th user-info-selected-column"
                  : "user-info-th"
              }
              onClick={() => handleSort("inprogressmounth")}
            >
              В работе
            </th>
            <th
              className={
                sortColumn === "onholdmounth"
                  ? "user-info-th user-info-selected-column"
                  : "user-info-th"
              }
              onClick={() => handleSort("onholdmounth")}
            >
              На паузе
            </th>
            <th
              className={
                sortColumn === "completedmounth"
                  ? "user-info-th user-info-selected-column"
                  : "user-info-th"
              }
              onClick={() => handleSort("completedmounth")}
            >
              Завершены
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((element, index) => (
            <tr className="user-info-table" key={index}>
              <td
                className="user-info-name"
                style={{ cursor: "pointer" }}
                onClick={() => handleUserClick(element.userId)}
              >
                {index < 3 ? getMedalImage(index) : null}
                {element.fio}
              </td>
              <td className="user-info-td">{element.inprogress}</td>
              <td className="user-info-td">{element.onhold}</td>
              <td className="user-info-td">{element.completed}</td>
              <td className="user-info-td">{element.inprogressmounth}</td>
              <td className="user-info-td">{element.onholdmounth}</td>
              <td className="user-info-td">{element.completedmounth}</td>
            </tr>
          ))}
          {getTotalRow()}
        </tbody>
      </table>
      {toastMessage && (
        <CustomToast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
    </div>
  );
};

export default Kpi;
