import React from "react";
import AveragrTimeTask from "../../components/adminStatistic/averageTimeTask";
import AveragrTimeTaskCategory from "../../components/adminStatistic/averageTimeTaskCategory";
import CashboxExpiring from "../../components/adminStatistic/cashboxExpiring";
import CompanyCategory from "../../components/adminStatistic/companyCategory";
import Control from "../../components/adminStatistic/control";
import Prevention from "../../components/adminStatistic/prevention";
import CategoryTimeStat from "../../components/adminStatistic/categoryTime";

const TaskStatistic = () => {
  return (
    <div>
      <div className="dashboard-grid">
        <AveragrTimeTask />
        <AveragrTimeTaskCategory />
        <CashboxExpiring />
        <CompanyCategory />
        <Control />
        <Prevention />
        <CategoryTimeStat />
      </div>
    </div>
  );
};

export default TaskStatistic;
