import React, { useState, useEffect } from "react";

const ForBookkeeping = ({ item, columns, setSelectedRow, handleOpenView }) => {
  const [items, setItems] = useState([]);
  const [activeSearchColumn, setActiveSearchColumn] = useState(null);
  const [columnSums, setColumnSums] = useState({});
  const [baseTariff, setBaseTariff] = useState(0);

  useEffect(() => {
    // Найти минимальный тариф среди записей, где есть cost_time
    const validTariffs = item
      .filter((row) => row.cost_time > 0 && parseFloat(row.preliminaryCost) > 0)
      .map((row) => parseFloat(row.preliminaryCost) / row.cost_time);

    const minTariff = validTariffs.length > 0 ? Math.min(...validTariffs) : 0;
    setBaseTariff(minTariff);

    // Обновить записи с расчетами
    const updatedItems = item.map((row) => {
      const calculatedTariff = row.tariff_time
        ? row.tariff_time * minTariff
        : 0;
      return {
        ...row,
        totalCost: parseFloat(row.preliminaryCost) + calculatedTariff,
        isInitialZero: parseFloat(row.preliminaryCost) === 0, // Для цветового выделения
      };
    });

    setItems(updatedItems);
  }, [item]);

  useEffect(() => {
    const sums = {};
    columns.forEach((column) => {
      if (column.type === "number") {
        const sum = items.reduce(
          (total, item) => total + (parseFloat(item[column.key]) || 0),
          0
        );
        sums[column.key] = sum;
      }
    });
    setColumnSums(sums);
  }, [items, columns]);

  const handleRowClick = (id) => {
    setSelectedRow(id);
    handleOpenView(id);
  };

  const handleColumnHeaderClick = (columnKey) => {
    setActiveSearchColumn(columnKey === activeSearchColumn ? null : columnKey);
  };

  const formatDateTime = (value) => {
    const date = new Date(value);
    return isNaN(date) ? "не указано" : date.toLocaleString("ru-RU");
  };

  return (
    <div className="grid-container">
      <div className="table-wrapper">
        <table className="table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  className={`${
                    activeSearchColumn === column.key ? "selected-column" : ""
                  }`}
                  onClick={() => handleColumnHeaderClick(column.key)}
                >
                  {column.title}
                </th>
              ))}
              <th>Общая стоимость</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id} onClick={() => handleRowClick(item.id)}>
                {columns.map((column) => (
                  <td key={`${item.id}-${column.key}`}>
                    {column.date
                      ? formatDateTime(item[column.key])
                      : item[column.key] || "Не указано"}
                  </td>
                ))}
                <td style={{ color: item.isInitialZero ? "green" : "red" }}>
                  {item.totalCost.toFixed(2)}
                </td>
              </tr>
            ))}
            <tr>
              {columns.map((column) => (
                <td key={`total-${column.key}`}>
                  {column.type === "number" ? columnSums[column.key] : ""}
                </td>
              ))}
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ForBookkeeping;
