import React, { useState, useEffect } from "react";
import ApiService from "../components/ApiService";

const Tracking = ({
  apiUrl,
  header,
  selectedRow,
  setSelectedRow,
  setModalOpen,
  listner,
}) => {
  const [tasks, setTasks] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchTasksProgress(); // вызов функции каждую минуту
    }, 60000); // 60000 миллисекунд = 1 минута

    fetchTasksProgress(); // также вызываем функцию при первом рендере

    return () => clearInterval(intervalId); // очистка интервала
  }, [listner]);

  const fetchTasksProgress = async () => {
    try {
      const response = await ApiService.fetchDataWithToken(apiUrl);
      setTasks(response.tasks);
    } catch (error) {
      console.error("Failed to fetch tasks", error);
    }
  };

  const handleRowClick = (id, deadline) => {
    setSelectedRow(id, deadline);
    setModalOpen(id);
  };

  const getProgressBarStyle = (progress, status, priority, pauseTime) => {
    const parsedProgress = parseFloat(progress);
    let color;

    switch (priority) {
      case "Низкий":
        color = "rgba(0, 123, 255, 0.7)"; // Мягкий синий для низкого приоритета
        break;
      case "Средний":
        color = "rgba(255, 193, 7, 0.7)"; // Мягкий желтый для среднего приоритета
        break;
      case "Высокий":
        color = "rgba(220, 53, 69, 0.7)"; // Мягкий красный для высокого приоритета
        break;
      case "Очень высокий":
        color = "rgba(255, 0, 25, 0.8)";
        break;
      default:
        color = "rgba(108, 117, 125, 0.7)"; // Мягкий серый для остальных
    }

    let background = `linear-gradient(to right, ${color} ${parsedProgress}%, transparent ${parsedProgress}%)`;

    if (status === "На паузе") {
      background = `linear-gradient(to right, rgba(108, 117, 125, 0.7) ${parsedProgress}%, transparent ${parsedProgress}%)`;
    }

    return {
      background: background,
      textAlign: "center",
      padding: "10px 15px",
      borderRadius: "4px",
      boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.2)",
      transition: "background 0.3s ease-in-out",
      fontWeight: "bold",
      letterSpacing: "0.5px",
      pauseTime,
    };
  };

  const sortTasks = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedTasks = [...tasks].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setTasks(sortedTasks);
  };

  const getColumnClassName = (key) => {
    return sortConfig.key === key ? "selected-column" : "";
  };

  return (
    <>
      <h1 className="tracking-header">
        {header} ({tasks.length})
      </h1>
      <div className="tracking-wrapper">
        <table className="table">
          <thead>
            <tr>
              <th
                className={getColumnClassName("taskId")}
                onClick={() => sortTasks("taskId")}
              >
                ИД задачи
              </th>
              <th
                className={getColumnClassName("companyName")}
                onClick={() => sortTasks("companyName")}
              >
                Название компании
              </th>
              <th
                className={getColumnClassName("user")}
                onClick={() => sortTasks("user")}
              >
                Ответственный
              </th>
              <th
                className={getColumnClassName("activeTime")}
                onClick={() => sortTasks("activeTime")}
              >
                Время активности
              </th>
            </tr>
          </thead>
          <tbody>
            {tasks.map((task) => {
              const rowStyle = getProgressBarStyle(
                task.progress,
                task.status,
                task.priority,
                task.pauseTime
              );
              return (
                <tr
                  key={task.taskId}
                  className={
                    task.priority === "Высокий" ||
                    task.priority === "Очень высокий"
                      ? "highlight-red"
                      : ""
                  }
                  style={rowStyle}
                  onClick={() => handleRowClick(task.taskId, task.deadline)}
                >
                  <td
                    className={rowStyle.pauseTime > 240 ? "fire-animation" : ""}
                  >
                    {task.taskId}
                  </td>
                  <td>{task.companyName}</td>
                  <td>{task.user}</td>
                  <td>{task.activeTime || "Нет данных"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Tracking;
